<template>
  <div class="bac">
    <div>
      <el-card shadow="always" style="width: 15rem">
        <el-cascader :options="options" :show-all-levels="true" v-model="this.subform.tag" placeholder="选择标签" @change="getInf"/>
        <el-table
            :data="info"
            highlight-current-row
            max-height="900"
            :header-cell-style="{'text-align':'center'}"
            :cell-style="{'text-align':'center'}">
          <el-table-column label="封面">
            <template #default="scope">
              <el-image :src="scope.row.avatar" style="width: 0.8rem;"></el-image>
            </template>
          </el-table-column>
          <el-table-column label="公司名称" prop="name"></el-table-column>
          <el-table-column label="注册资金" prop="bankroll"></el-table-column>
          <el-table-column label="规模(人)" prop="size"></el-table-column>
          <el-table-column label="标签" prop="tag"></el-table-column>
          <el-table-column label="操作" width="500">
            <template #default="scope">
              <el-button @click="this.$router.push({path:'/Company',query: {sid: scope.row.id}})">查看</el-button>
              <el-button @click="this.$router.push({path:'/AddSupply',query: {companyId: scope.row.id, }})">编辑信息</el-button>
              <el-button @click="this.$router.push({path:'/AllProject',query: {sid: scope.row.id}})">项目管理</el-button>
              <el-button @click="this.$router.push({path:'/SuAllCase',query: {sid: scope.row.id}})">案例管理</el-button>
<!--              <el-button @click="this.$router.push({path:'/AllAdvance',query: {sid: scope.row.id}})">优势管理</el-button>-->
              <el-button @click="this.$router.push({path:'/AllCertificate',query: {sid: scope.row.id}})">证书管理</el-button>
              <el-button type="danger" @click="Delete(scope.row.id)">删除</el-button>
            </template>
          </el-table-column>
        </el-table>
      </el-card>
    </div>
  </div>
</template>

<script>
export default {
  name: "AllSupply",
  data(){
    return{
      options:[
        {
          value: '',
          label: '全部',
        },{
          value: '结构设计',
          label: '结构设计',
        },{
          value: '外观设计',
          label: '外观设计',
        },{
          value: '软硬件开发',
          label: '软硬件开发',
        },{
          value: '手板制造',
          label: '手板制造',
        },{
          value: '生产制造',
          label: '生产制造',
        },{
          value: '包装制造',
          label: '包装制造',
        }],
      subform:{
        tag: '',
        currentPage:1,
        pageSize: 20,
      },

      deleteFrom:{
        sid:'',
      },

      info:[''],
      total: 0,

    }
  },
  mounted() {
    this.getInf()
  },
  methods:{
    getInf() {
      this.$http({
        method: 'post',
        url: '/admin/getSuppliers',
        data: this.subform,
      }).then(res => {
        this.info = res.data.data;
        this.total = res.data.count;
        this.subform.currentPage = res.data.currentPage;
      })
    },
    Delete(index){
      this.deleteFrom.sid = index;
      this.$http({
        method: 'post',
        url: '/admin/deleteSupplier',
        data: this.deleteFrom,
      }).then(res => {
        if (res.data.code === 1){
          this.$notify({
            title: '删除成功',
            message: res.data.message,
            type: 'success'
          });
          this.getInf();
        }
      })
    },
  }
}
</script>

<style scoped>
.bac{
  height: 11.94rem;
}
</style>